<template>
  <div>
    <cs-table
      :search-attr="{ sm: '4' }"
      search-input-con-class=" "
      :filterable-attr="{ sm: '3' }"
      :loading="loading"
      :headers="headers"
      :items="items"
      :pagination="pagination"
      base-classes="card card-custom card-stretch gutter-b rounded-top-0"
      filterable-name="Status"
      filterable-key="application_status"
      :filterable-list="statuses"
      @filtered="handelFiltering"
    >
      <template #after-header-filterable>
        <b-col class="my-2 my-md-0 ml-auto text-right">
          <b-btn variant="light-primary" :disabled="!coopReadyToPayment" class="font-weight-bolder" @click="openModal">
            <i class="fas fa-user"></i>
            Invite Applicant
          </b-btn>
        </b-col>
      </template>

      <template #[`item.first_name`]="{ item }">
        <template v-if="get(item, 'attributes.first_name') || get(item, 'attributes.last_name')">
          <span :id="`${item.id}_applicant`">{{
            get(item, 'attributes.first_name') + ' ' + get(item, 'attributes.last_name')
          }}</span>
          <b-tooltip :target="`${item.id}_applicant`" placement="top">
            {{ get(item, 'attributes.email') }}
          </b-tooltip>
        </template>
        <template v-else>
          {{ get(item, 'attributes.email') }}
        </template>
      </template>

      <template #[`item.loan_to_value`]="{ item }">
        <template v-if="get(item, 'attributes.application.data.attributes.loan_to_value')">
          <span
            class="font-weight-bolder"
            :class="[
              calculateLoanRatio(get(item, 'attributes.application.data.attributes.loan_to_value')) >
              get(item, 'attributes.cooperative.data.attributes.maximum_loan_ratio', 0)
                ? 'text-danger'
                : 'text-success',
            ]"
          >
            {{ calculateLoanRatio(get(item, 'attributes.application.data.attributes.loan_to_value')) + '%' }}
          </span>
        </template>
        <template v-else> - </template>
      </template>

      <template #[`item.debt_to_income`]="{ item }">
        <template v-if="get(item, 'attributes.application.data.attributes.debt_to_income')">
          <span
            class="font-weight-bolder"
            :class="[
              calculateDebtIncomeRatio(get(item, 'attributes.application.data.attributes.debt_to_income')) >
              get(item, 'attributes.cooperative.data.attributes.maximum_debt_ratio', 0)
                ? 'text-danger'
                : 'text-success',
            ]"
          >
            {{ calculateDebtIncomeRatio(get(item, 'attributes.application.data.attributes.debt_to_income')) + '%' }}
          </span>
        </template>
        <template v-else> - </template>
      </template>

      <template #[`item.status`]="{ item }">
        <template v-if="getApplication(item, 'status')">
          <template
            v-if="
              getApplication(item, 'days_to_left', null) != null &&
                Number(getApplication(item, 'days_to_left', null)) <= 0 &&
                getApplication(item, 'rejected')
            "
          >
            <span
              :id="`application_${item.id}`"
              class="label-light-danger white-space-nowrap label font-weight-bold label-lg label-inline"
            >
              {{ autoRejectStatusText }}
            </span>
          </template>
          <template v-else>
            <span
              :id="`application_${item.id}`"
              :class="[`label-light-${getBy(statuses, 'text', getApplication(item, 'status')).color}`]"
              class="white-space-nowrap label font-weight-bold label-lg label-inline"
            >
              {{ getApplication(item, 'status') }}
            </span>

            <b-tooltip
              v-if="isReason(getApplication(item, 'status')) && getApplication(item, 'reason')"
              :target="`application_${item.id}`"
              placement="top"
            >
              {{ getApplication(item, 'reason') }}
            </b-tooltip>
            <b-tooltip
              v-if="
                getApplication(item, 'status') == 'Coordinator Review' && getApplication(item, 'resubmited_at', null)
              "
              :target="`application_${item.id}`"
              placement="top"
            >
              The application re-submited at {{ getApplication(item, 'resubmited_at', null) | moment('LLLL') }}
            </b-tooltip>

            <template v-if="isInterview(getApplication(item, 'status'))">
              <p class="mt-1 mb-0">
                {{ getApplication(item, 'last_interview_schedule.data.attributes.date') }}
                {{ getApplication(item, 'last_interview_schedule.data.attributes.time') | moment('hh:mm A') }}
              </p>
            </template>
          </template>
        </template>

        <template v-else>-</template>
      </template>

      <template #[`item.actions`]="{ item }">
        <b-dropdown class="btn-icon" variant="clean" menu-class="w-max-content" right no-caret>
          <template v-slot:button-content>
            <i class="ki ki-bold-more-hor font-size-md pr-0"></i>
          </template>

          <ul class="navi navi-hover py-5">
            <li v-if="hasViewAction(get(item, 'attributes.application.data.attributes'))" class="navi-item">
              <router-link
                :to="{ name: 'application-details', params: { id: get(item, 'attributes.application.data.id') } }"
                v-slot="{ href, navigate }"
              >
                <a :href="href" class="navi-link" @click="navigate">
                  <span class="navi-icon">
                    <i class="la la-eye font-size-h1 mr-2"></i>
                  </span>
                  <span class="navi-text">View</span>
                </a>
              </router-link>
            </li>
            <li class="navi-item">
              <a class="navi-link" href="javascript:;" @click="openModal(item)">
                <span class="navi-icon">
                  <i class="la la-refresh font-size-h1 mr-2"></i>
                </span>
                <span class="navi-text">Update and Resend Email</span>
              </a>
            </li>
          </ul>
        </b-dropdown>
      </template>
    </cs-table>

    <b-modal
      v-model="modal"
      :title="isEdit ? 'Update and Resend Email' : 'Invite Applicant'"
      centered
      footer-class="text-right"
    >
      <b-form-group :description="!isEdit ? 'You can send invite multiple applicants.' : ''">
        <template v-slot:label>
          Email
          <span class="text-danger">*</span>
        </template>
        <b-form-input v-model="form.email" :state="validateState($v.form.email)" />
      </b-form-group>

      <template v-slot:modal-footer>
        <b-btn variant="primary" class="text-uppercase" v-cs-loading="submiting" @click="handleSubmit">
          {{ 'Send Invite' }}
        </b-btn>
      </template>
    </b-modal>

    <b-modal v-model="confirmationModal" centered title="Attention" title-class="h4" hide-footer>
      <b-card-text>Are you sure you want to <b class="text-danger">delete</b> </b-card-text>
      <div class="d-flex justify-content-end">
        <b-btn variant="primary" class="mr-2" v-cs-loading="submiting" @click="destroyItem">Delete</b-btn>
        <b-btn variant="white" @click="confirmationModal = false">Cancel</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import applicationLoans from '@/mixins/application-loans';
import applicationActions from '@/mixins/application-actions';

export default {
  name: 'cooperatives-view-applicants',
  mixins: [applicationLoans, applicationActions],
  props: ['detailsData'],
  validations() {
    const rules = {
      form: {
        id: {},
        email: { required },
      },
    };

    if (this.isEdit) rules.form.email.email = email;

    return rules;
  },
  data() {
    return {
      form: {},
      submiting: false,
      loading: false,
      modal: false,
      confirmationModal: false,

      headers: [
        {
          text: 'Name',
          value: 'first_name',
          sortable: true,
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Credit Score',
          value: 'attributes.credit_score',
          falsy: '-',
          align: 'center',
        },
        {
          text: 'Loan to Value',
          value: 'loan_to_value',
          falsy: '-',
          align: 'center',
        },
        {
          text: 'Debt to Income',
          value: 'debt_to_income',
          falsy: '-',
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'applicants/items',
      pagination: 'applicants/pagination',
    }),
    isEdit() {
      return this.get(this.form, 'id', null);
    },
    cooperativeId() {
      return this.$route.params.cooperativeId;
    },
    coopReadyToPayment() {
      for (const index in this.detailsData.ready_to_payment) {
        if (!this.detailsData.ready_to_payment[index]) {
          return false;
        }
      }

      return true;
    },
  },
  async mounted() {
    this.$root.$emit('changeTitle', this.detailsData.company_name);
    await this.handleItems();
  },

  methods: {
    ...mapActions({
      getItems: 'applicants/index',
      updateItem: 'applicants/update',
      createItem: 'applicants/create',
      deleteItem: 'applicants/delete',
      inviteItem: 'applicants/invite',
    }),
    getApplication(item, fields) {
      const app = this.get(item, 'attributes.application.data.attributes', {});
      return fields ? this.get(app, fields) : app;
    },
    hasViewAction(item) {
      return item.step_status >= 6 && item.status && item.status != 'Invite Pending' && item.status != 'In Progress';
    },
    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleItems();
    },
    handleItems: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.where == undefined) query.where = {};

      if (query.where.cooperative_id != this.cooperativeId) query.where.cooperative_id = this.cooperativeId;

      if (query.application_status != undefined) query.where.application_status = query.application_status;

      if (query.page == undefined) query.page = 1;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getItems(query));

      this.loading = false;
    }, 400),

    handleSubmit() {
      if (!this.$v.$invalid) {
        this.submiting = true;
        let params = {};
        const action = this.form.id ? this.updateItem : this.inviteItem;

        if (this.form.id) {
          params = { admin_user: this.form, resend_email: true };
          // Update and ResendEmail
        } else {
          // Invite Applicants
          params = this.jsonToFormData({
            cooperative_id: this.form.cooperative_id,
            emails: this.form.email.split(','),
          });
        }

        action(params)
          .then((result) => {
            setTimeout(() => {
              this.handleItems();
            }, 500);
            this.modal = false;
          })
          .finally(() => {
            this.submiting = false;
          });
      } else {
        this.$v.$touch();
      }
    },

    destroyItem(data = {}) {
      this.submiting = true;
      this.deleteItem(this.form.id)
        .then((result) => {
          this.confirmationModal = false;
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    initForm(data = {}) {
      this.$v.$reset();
      this.form = {
        id: data.id || '',
        email: this.get(data, 'attributes.email'),
        cooperative_id: this.cooperativeId,
      };
    },

    openModal(data = {}, type = 'modal') {
      this.initForm(data);
      this[type] = true;
    },
  },
};
</script>
